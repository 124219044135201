import React, { useEffect } from 'react';
import { createFileRoute, Outlet } from '@tanstack/react-router';
import NavTopbar from '@/components/Navigation/NavTopbar.jsx';
import TransitionLayout from '@/components/Animations/TransitionLayout.jsx';
import Footer from '@/components/Home/Footer.jsx';
import { useIdleTimerContext } from "react-idle-timer";

function Layout() {
  const { pause, resume, activate } = useIdleTimerContext();

  useEffect(() => {
    activate();
    pause();
    return () => {
      resume();
    };
  }, [pause, resume]);

  return (
    <div className="flex flex-row font-body">
      <div className=" flex w-full min-w-0 flex-col">
        <div className="flex min-h-screen w-full min-w-0 flex-col pb-5">
          <NavTopbar inCustomerView/>
          <TransitionLayout className="container flex grow flex-col gap-20 px-10 py-5 font-body max-xl:px-4">
            <Outlet/>
          </TransitionLayout>
        </div>
        <Footer/>
      </div>
    </div>
  );
}

// eslint-disable-next-line import/prefer-default-export
export const Route = createFileRoute('/_reduced-layout')({
  loader: ({ context }) => context?.user.ready().catch(() => {
    context.auth.logout();
  }),
  beforeLoad: async ({ context }) => context.user.ready().catch(() => {
    context.auth.logout();
  }),
  component: () => <Layout/>,
  staticData: {
    needsAuth: true,
  },
});
