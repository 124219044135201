import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '@/components/ui/dialog.jsx';
import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';

export default function Modal({
  open = false, onClose, title, children, className = '',
}) {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(open);
  }, [open]);

  const handleOnOpenChange = (newVisible) => {
    setVisible(newVisible);
    if (!newVisible) {
      onClose();
    }
  };

  return (
    <Dialog
      open={visible}
      onOpenChange={handleOnOpenChange}
      className="block"
    >
      <DialogContent className={cn(className, 'flex max-h-screen border-0 bg-[#F2F2F4] p-0')}>
        <DialogHeader>
          <DialogTitle className="flex h-[61px] flex-col justify-center rounded-t-2xl bg-gradient-to-r from-[#FF585D] from-15% to-[#FFC100] to-[87%] px-4 font-body text-[22px] font-[600] text-white md:px-6">
            {title}
          </DialogTitle>
          <div className="!m-0 min-h-0 flex-1">
            {children}
          </div>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  );
}

Modal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
