import React, {
  createContext, useState, useContext, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';

const LoadingScreenContext = createContext();

function Loader({ text }) {
  return (
    <div className="fixed left-0 top-0 z-[99999] flex h-screen w-screen flex-col items-center justify-center gap-3 bg-white/50">
      <div className="relative size-16">
        <div className="loading-spinner aspect-1 slow absolute inset-0 flex animate-spin items-center justify-center rounded-full" />
      </div>
      <p className="font-base">{text}</p>
    </div>
  );
}

export function LoadingScreenProvider({ children }) {
  const [loadingScreenVisible, setLoadingScreenVisible] = useState(false);
  const [loadingScreenText, setLoadingScreenText] = useState('Lädt...');

  const values = useMemo(() => ({
    loadingScreenVisible,
    setLoadingScreenVisible,
    loadingScreenText,
    setLoadingScreenText,
  }), [loadingScreenVisible, setLoadingScreenVisible, loadingScreenText, setLoadingScreenText]);

  return (
    <LoadingScreenContext.Provider
      value={values}
    >
      {children}
      {loadingScreenVisible && (
        <Loader text={loadingScreenText} />
      )}
    </LoadingScreenContext.Provider>
  );
}

LoadingScreenProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useLoadingScreen = () => useContext(LoadingScreenContext);

export function LoadingScreen({ text }) {
  const { setLoadingScreenVisible, setLoadingScreenText } = useLoadingScreen();

  useEffect(() => {
    setLoadingScreenVisible(true);
    setLoadingScreenText(text);

    return () => {
      setLoadingScreenVisible(false);
      setLoadingScreenText('Lädt...');
    };
  }, [text, setLoadingScreenVisible, setLoadingScreenText]);

  return null;
}
