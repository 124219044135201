import useApiMutation from '@/components/Hooks/useApiMutation';
import { useCallback, useRef, useState } from 'react';

export default function useApiPaginator(
  endpoint,
  perPage,
  method = 'POST',
  middlewares = [],
  mutOpts = {},
) {
  const [content, setContent] = useState([]);
  const [total, setTotal] = useState(0);

  const pageDataRef = useRef(null);

  const currentPage = () => (pageDataRef.current !== null ? pageDataRef.current.current_page : 0);
  const lastPage = () => (pageDataRef.current !== null ? pageDataRef.current.last_page : 1);
  const nextPage = () => currentPage() + 1;
  const prevPage = () => currentPage() - 1;

  const {
    mutateAsync: fetchContent,
    ...rest
  } = useApiMutation(
    method,
    middlewares,
    mutOpts,
    endpoint,
  );

  const getPageData = useCallback(
    async (page, params = {}) => fetchContent({
      body: {
        page,
        per_page: perPage || 10,
        ...params,
      },
    }),
    [fetchContent, perPage],
  );

  const loadPage = useCallback(
    async (page, params = {}) => {
      const response = await getPageData(page, params);
      if (!response || !response.data || !response.meta) return null;
      pageDataRef.current = response.meta;
      setContent(response.data);
      setTotal(response.meta.total);
      return response;
    },
    [getPageData],
  );

  const clearPage = useCallback(() => {
    setContent([]);
    setTotal(0);
    pageDataRef.current = null;
  }, []);

  const loadNextPage = useCallback(
    async (params = {}) => {
      if (currentPage() >= lastPage()) return null;
      return loadPage(nextPage(), params);
    },
    [loadPage],
  );

  const loadPrevPage = useCallback(
    async (params = {}) => {
      if (currentPage() <= 1) return null;
      return loadPage(prevPage(), params);
    },
    [loadPage],
  );

  const loadFirstPage = useCallback(
    async (params = {}) => loadPage(1, params),
    [loadPage],
  );

  const loadLastPage = useCallback(
    async (params = {}) => loadPage(lastPage(), params),
    [loadPage],
  );

  return {
    ...rest,
    content,
    total,
    loadPage,
    clearPage,
    loadNextPage,
    loadPrevPage,
    loadFirstPage,
    loadLastPage,
    currentPage: currentPage(),
    lastPage: lastPage(),
  };
}
