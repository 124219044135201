import React from 'react';
import UserAvatar from '@/components/Users/UserAvatar.jsx';
import { cn } from '@/lib/utils';
import PropTypes from 'prop-types';

const UserContainer = React.forwardRef(({
  user = null, className = '', children = null,
}, ref) => (
  <div className="flex flex-row gap-4" ref={ref}>
    <UserAvatar user={user} />
    <div className={cn('flex min-w-0 flex-1 flex-col', className)}>
      {children}
    </div>
  </div>
));
UserContainer.displayName = 'UserContainer';

UserContainer.propTypes = {
  className: PropTypes.string,
  user: PropTypes.object,
  children: PropTypes.node,
};

export default UserContainer;
