import { IdleTimerProvider } from 'react-idle-timer';
import AFKModal from '@/components/Popups/AFKModal.jsx';
import { useAuth } from '@/contexts/AuthContext.jsx';

export default function AFKProvider({ children = null }) {
  const { logout } = useAuth();

  const onIdle = () => {
    logout();
  };

  return (
    <IdleTimerProvider
      timeout={1000 * 60 * 30} // 30 minutes
      promptBeforeIdle={1000 * 60} // 1 minute
      onIdle={onIdle}
      crossTab
      syncTimers={1000}
    >
      {children}
      <AFKModal/>
    </IdleTimerProvider>
  );
}
