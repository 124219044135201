import React, { useEffect, useRef } from 'react';

export default function InView({
  children, onEnter, onLeave = () => {}, ...props
}) {
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          onEnter();
        } else {
          onLeave();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      },
    );

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [onEnter, onLeave]);

  return <div ref={ref} {...props}>{children}</div>;
}
