import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button } from "@/components/ui/button.jsx";
import { useUser } from "@/contexts/UserContext.jsx";

const frequencies = [
  {
    id: 1,
    value: 'weekly',
    label: 'Ich möchte einmal pro Woche eine Mail erhalten.'
  },
  {
    id: 2,
    value: 'monthly',
    label: 'Ich möchte einmal pro Monat eine Mail erhalten.'
  },
  {
    id: 3,
    value: 'never',
    label: 'Ich möchte keine Mails zu Aktivitäten erhalten.'
  }
];

function NotificationSettings() {
  const { user, updateNotificationFrequency } = useUser();
  const frequency = useMemo(() => user.email_notification_frequency, [user]);

  const handleSettingsUpdate = (event) => {
    if (frequency === event.target.value) return;
    updateNotificationFrequency(event.target.value);
  };
  return (
    <React.Fragment>
      <div className="flex flex-col gap-1">
        <p>Wir bieten Ihnen an, Sie in regelmäßigen abständen zu Aktivitäten auf der Plattform zu
          informieren.</p>
        <p>Sie erhalten nur Informationen zu Adboards und Foren an denen Sie teilnehmen.</p>
        <p>Sie haben jederzeit die Möglichkeit diese Einstellung anzupassen.</p>
      </div>
      <p className="font-semibold">Wie häufig möchten Sie über Aktivitäten auf der Plattform informiert
        werden?</p>
      <div className="flex flex-col gap-4 items-center sm:items-start">
        {frequencies.map((option) => (
          <div key={option.id} className="flex items-center gap-2">
            <input type="radio" name="notification" id={option.value} value={option.value}
                   className="cursor-pointer" onChange={handleSettingsUpdate}
                   checked={frequency === option.value}/>
            <label className="cursor-pointer" htmlFor={option.value}>{option.label}</label>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
}


NotificationSettings.propTypes = {
  className: PropTypes.string,
};

export default NotificationSettings;
