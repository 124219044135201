import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@/components/ui/button.jsx';
import { cn } from '@/lib/utils';
import { useNavigate } from '@tanstack/react-router';

export default function AdminButton({ inCustomerView = true, className = '' }) {
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate({
      to: inCustomerView ? '/admin/' : '/',
    });
  };

  return (
    <Button
      className={cn(
        'min-w-fit gap-1 self-end rounded-full sm:px-8 px-6 py-2 font-semibold',
        className,
      )}
      onClick={handleOnClick}
    >
      {inCustomerView ? 'Zum Admin Portal' : 'Zur CoCreation Platform'}
    </Button>
  );
}

AdminButton.propTypes = {
  inCustomerView: PropTypes.bool,
  className: PropTypes.string,
};
