import useApiPaginator from '@/components/Hooks/useApiPaginator';
import { useCallback, useState } from 'react';

export default function useApiInfinitePaginator(
  endpoint,
  perPage,
  method = 'POST',
  middlewares = [],
  mutOpts = {},
) {
  const [content, setContent] = useState([]);

  const {
    total,
    clearPage,
    loadNextPage,
    loadPage: internalLoadPage,
    ...rest
  } = useApiPaginator(
    endpoint,
    perPage,
    method,
    middlewares,
    mutOpts,
  );

  const loadNext = useCallback(
    async (params) => {
      const response = await loadNextPage(params);
      if (!response || !response.data || !response.meta) return null;
      setContent((prev) => [...prev, ...response.data]);
      return response;
    },
    [loadNextPage],
  );

  const loadPage = useCallback(
    async (page, params = {}) => {
      const response = await internalLoadPage(page, params);
      if (!response || !response.data || !response.meta) return null;
      setContent(response.data);
      return response;
    },
    [internalLoadPage],
  );

  const clear = useCallback(() => {
    setContent([]);
    clearPage();
  }, [clearPage]);

  return {
    ...rest,
    loadPage,
    content,
    total,
    loadNext,
    clear,
  };
}
