import React from 'react';
import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';
import { Button } from './button';
import { Tooltip, TooltipArrow, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip.jsx";

const IconButton = React.forwardRef(({ icon, tooltip = undefined, className = '', buttonOpacity = 1, ...props }, ref) => {
  const buttonContent = (
    <Button
      ref={ref}
      className={cn(
        'bg-transparent text-black hover:bg-[#ffffff33]',
        className,
      )}
      style={{ opacity: buttonOpacity }}
      {...props}
    >
      {icon}
    </Button>
  );

  if (!tooltip) {
    return buttonContent;
  }

  return (
    <TooltipProvider>
      <Tooltip delayDuration={200}>
        <TooltipTrigger asChild>
          {buttonContent}
        </TooltipTrigger>
        <TooltipContent
          className="max-w-72 !animate-none rounded-xl border-none bg-[#3F3F46] p-4 font-medium text-white"
          style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
          side="right"
        >
          {tooltip}
          <TooltipArrow className="size-8 h-3 w-6 fill-[#3F3F46]" />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
});

IconButton.displayName = 'IconButton';

IconButton.propTypes = {
  icon: PropTypes.node.isRequired,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  buttonOpacity: PropTypes.number,
};

export default IconButton;
