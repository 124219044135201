import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import AuthMiddleware from '@/Middlewares/AuthMiddleware.js';
import useApiMutation from '@/components/Hooks/useApiMutation.js';
import InView from '@/components/Navigation/InView.jsx';
import useApiInfinitePaginator from '@/components/Hooks/useApiInfinitePaginator.js';
import { cn } from '@/lib/utils.js';

const NotificationContext = createContext();

export default function NotificationProvider({
  className = '', children, perPage = 10,
}) {
  const [inView, setInView] = useState(false);

  const {
    content: notifications,
    loadPage,
    loadNext,
    currentPage,
    status,
    total,
  } = useApiInfinitePaginator(
    '/api/notification',
    perPage,
    'GET',
    [AuthMiddleware],
  );

  const allRead = useMemo(() => {
    if (notifications.length === 0) return true;
    return notifications.at(-1).all_read;
  }, [notifications]);

  const {
    mutateAsync: internalMarkAsRead,
  } = useApiMutation(
    'POST',
    [AuthMiddleware],
    {},
    '/api/notification/mark-as-read',
  );

  const {
    mutateAsync: internalCheckNew,
  } = useApiMutation(
    'POST',
    [AuthMiddleware],
    {},
    '/api/notification/check',
  );

  useEffect(() => {
    if (!inView) return;
    loadPage(1);
  }, [inView, loadPage]);

  const reload = useCallback(() => {
    loadPage(currentPage);
  }, [loadPage, currentPage]);

  const resetPages = useCallback(() => {
    loadPage(1);
  }, [loadPage]);

  const unreadIds = useMemo(() => {
    if (allRead) return [];
    return notifications
      .slice((currentPage - 1) * perPage, currentPage * perPage)
      .filter((n) => !n.read)
      .map((n) => n.id);
  }, [notifications, currentPage, perPage, allRead]);

  const markAsRead = useCallback(async () => {
    if (unreadIds.length === 0) return;
    await internalMarkAsRead({
      body: { ids: unreadIds },
    });
  }, [unreadIds, internalMarkAsRead]);

  useEffect(() => {
    if (currentPage < 2) return;
    markAsRead();
  }, [notifications, currentPage]);

  const checkNewNotifications = useCallback(async () => {
    const response = await internalCheckNew({
      body: { last_id: notifications[0]?.id },
    });
    if (response.new_notifications) {
      await resetPages();
    }
  }, [internalCheckNew, currentPage, markAsRead, notifications]);

  const updateNotifications = useCallback(() => {
    loadPage(1);
  }, [loadPage]);

  const values = useMemo(() => ({
    notifications,
    allRead,
    status,
    totalPosts: total,
    reload,
    loadNext: status !== 'success' ? () => {} : loadNext,
    loading: status !== 'success',
    resetPages,
    markAsRead,
    checkNewNotifications,
    updateNotifications,
  }), [
    notifications,
    allRead,
    status,
    total,
    reload,
    loadPage,
    currentPage,
    loadNext,
    resetPages,
    markAsRead,
    checkNewNotifications,
    updateNotifications,
  ]);

  return (
    <InView
      onEnter={() => setInView(true)}
      onLeave={() => {}}
      className={cn('', className)}
    >
      {inView && (
        <NotificationContext.Provider value={values}>
            { children }
        </NotificationContext.Provider>
      )}
    </InView>
  );
}

export const useNotification = () => useContext(NotificationContext);
