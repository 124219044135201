import React, { useEffect } from 'react';
import { BellIcon } from '@heroicons/react/24/outline';
import Notification from '@/components/Popups/Notification.jsx';
import { useNotification } from '@/components/Notification/NotificationProvider.jsx';

export default function NotificationButton() {
  const [openModal, setOpenModal] = React.useState(false);
  const {
    markAsRead, resetPages, allRead, checkNewNotifications, notifications,
  } = useNotification();

  const handleModalOpen = () => {
    setOpenModal(true);
    markAsRead();
  };

  const handleClose = () => {
    setOpenModal(false);
    resetPages();
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkNewNotifications();
    }, 60000); // 1 minute
    return () => clearInterval(intervalId);
  }, [notifications]);

  return (
    <React.Fragment>
      <div className="relative">
        <BellIcon className="flex size-8 cursor-pointer" onClick={handleModalOpen} />
        {!allRead && (
        <span className="absolute right-[-6px] top-0 size-2.5 rounded-full bg-primary" />
        )}
      </div>

      <Notification open={openModal} onClose={handleClose} />
    </React.Fragment>
  );
}
